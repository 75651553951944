import 'current-script-polyfill';
import Vue from 'vue'
import App from './App.vue'
Vue.config.productionTip = false
import router from './router';
import { Cell, CellGroup, Button } from 'vant';
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Button);
import 'amfe-flexible';

import VConsole from 'vconsole';
if(process.env.VUE_APP_ENV  == 'development'){
  new VConsole();
}
new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
