import Vue from 'vue';
import VueRouter from "vue-router";

Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  routes: [
    { path: "/douyingShare", component: ()=>import('@/views/douyingShare.vue') },
    { path: "/share", component: ()=>import('@/views/share.vue') },
    { path: "/paySuccess", component: ()=>import('@/views/paySuccess.vue') },
  ],
})

export default router
