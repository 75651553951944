<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
/* @import url('./style/reset.css'); */
body,
html,
#app {
   height: 100%;
   /* max-width: 900px; */
   margin: 0 auto;
}
</style>
